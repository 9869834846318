import axios from 'axios'
import {showToast} from "@nutui/nutui";
import '@nutui/nutui/dist/packages/toast/style';
import store from '@/store'
import {getToken} from '@/utils/auth'
import router from "@/router";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    if (response.status >= 400) {
      showToast.fail(res.message || res.detail || 'Error')
      return Promise.reject(new Error(res.message || res.detail || 'Error'))
    } else {
      return res
    }
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch('user/logout')
      router.push(`/login`)
      showToast.fail('登录已过期，请重新登录')
    } else if (error.response.status === 403) {
      showToast.fail('访问频率过高， 请稍后再试')
    } else {
      showToast.fail(error.response.data.message || error.message)
    }
    return Promise.reject(error)
  }
)

export default service
